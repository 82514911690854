import type htmxType from 'htmx.org';

import 'htmx.org/dist/ext/class-tools.js';
import 'htmx.org/dist/ext/preload.js';
import 'htmx.org/dist/ext/head-support.js';
import 'htmx.org/dist/ext/ajax-header.js';
import 'htmx.org/dist/ext/disable-element.js';
import 'htmx.org/dist/ext/event-header.js';
import 'htmx.org/dist/ext/json-enc.js';
import 'htmx.org/dist/ext/loading-states.js';
import 'htmx.org/dist/ext/method-override.js';
import 'htmx.org/dist/ext/multi-swap.js';
import 'htmx.org/dist/ext/path-deps.js';
import 'htmx.org/dist/ext/remove-me.js';
import 'htmx.org/dist/ext/restored.js';
import 'htmx.org/dist/ext/sse.js';
import 'htmx.org/dist/ext/ws.js';

const htmx = (window as any).htmx as typeof htmxType;
htmx.config.allowEval = false;
// this enables cookies to be used
htmx.config.withCredentials = true;
(htmx.config as any).inlineScriptNonce = 'myscript'; // wrong types :(
// htmx.config.getCacheBusterParam

let twitchAuth: Twitch.ext.Authorized | undefined;
let twitchCtx: Partial<Twitch.ext.Context> | undefined;
let twitchExtVisible: boolean | undefined;
if (Twitch) {
	// https://dev.twitch.tv/docs/extensions/reference#javascript-helper
	Twitch.ext.onContext((ctx) => {
		twitchCtx = { ...twitchCtx, ...ctx };
		// segment.setStreamInfo(ctx);
		Twitch.ext.onVisibilityChanged((visible: boolean) => {
			twitchExtVisible = visible;
		});
	});
	Twitch.ext.onAuthorized((auth) => {
		//console.log("The JWT that will be passed to the EBS is", auth.token);
		//console.log("The channel ID is", auth.channelId);
		twitchAuth = auth;
		htmx.trigger(window.document.body, 'tangiaTwitchAuth', {});
		Twitch.ext.onContext((ctx) => {
			twitchCtx = { ...twitchCtx, ...ctx };
			// segment.setStreamInfo(ctx);
			Twitch.ext.onVisibilityChanged((visible: boolean) => {
				twitchExtVisible = visible;
			});
		});
	});
}

/*
var requestConfig = {
    boosted: eltIsBoosted,
    parameters: filteredParameters,
    unfilteredParameters: allParameters,
    headers:headers,
    target:target,
    verb:verb,
    errors:errors,
    withCredentials: etc.credentials || requestAttrValues.credentials || htmx.config.withCredentials,
    timeout:  etc.timeout || requestAttrValues.timeout || htmx.config.timeout,
    path:path,
    triggeringEvent:event
};
*/
document.body.addEventListener('htmx:configRequest', function (evt: any) {
	if (evt.detail.path.startsWith('/')) {
		// staging
		if (window.location.href.includes('g1zdt5fmrq8bwmug6byidavqirh6u6')) {
			evt.detail.path = 'https://staging.tangialeaderboardfrontend.pages.dev' + evt.detail.path;
		} else if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost')) {
			// keep relative during development
		} else {
			// default to production
			evt.detail.path = 'https://leaderboard-frontend.cf.tangia.co' + evt.detail.path;
		}
	}
	evt.detail.headers['Authorization'] = 'Bearer ' + (twitchAuth?.token || 'MISSING-TOKEN');
	evt.detail.headers['X-Twitch-Features'] = JSON.stringify(Twitch?.ext?.features || {});
	evt.detail.headers['X-Twitch-Context'] = JSON.stringify(twitchCtx || {});
	evt.detail.headers['X-Twitch-Visible'] = `${twitchExtVisible}`;
});
